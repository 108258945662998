<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo">Cadastro de Recursos</h1>

      <div class="box-table">
        <v-skeleton-loader
          :loading="loadingTable"
          :transition="'fade-transition'"
          height="500"
          type="table">
          <v-data-table
            :headers="headers"
            :items="listaItens"
            :search="search"
            width="300px"
            flat
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage"> 
            <template v-slot:top>
              <BrToolBar @search="buscar" :configFilter="{ listaSelect: item, getItens: getItens, jsonData: false }" />
            </template>

            <template v-slot:item.action="{ item }">
                <v-btn
                  v-if="permiteAcao($route, 'edit')"
                  small
                  @click="editItem(item)"
                  tile
                  outlined 
                  :color="variables.colorPrimary"
                  class="mr-2"
                >
                  Editar
                </v-btn>

                <v-btn
                  v-if="permiteAcao($route, 'delete')"
                  small
                  @click="deleteItem(item)"
                  tile
                  outlined 
                  :color="variables.colorError"
                >
                  Excluir
                </v-btn>
            </template>

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ pageStart }}
                  -
                  {{ pageStop }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="7"
                  class="box-table-paginacao"
                  v-if="numberOfPages > 1"></v-pagination>
              </div>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>

      <v-btn class="mx-2 fab-cadastrar-usuario" fab color="primary" @click="cadastrar">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import Alert from 'sweetalert2'
import listagemTables from '@/views/mixins/listagemTables'

export default {
  name: 'Cadastro',
  components: {
    BrToolBar: () => import('./components/toolbar')
  },
  mixins: [listagemTables],
  data: () => ({
    headers: [
      { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'Nome', value: 'name' },
      { align: 'start', class: 'table-header', text: 'caminho', value: 'path' },
      { align: 'start', class: 'table-header', text: 'Nome grupo', value: 'nameGroup' },
      { align: 'start', class: 'table-header', text: 'Data', value: 'created' },
      { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
    ],
  }),
  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('endpoints', ['listaItens', 'item', 'totalItens']),
    ...mapGetters('grupos', {itemGroups: 'listaItens'})
  },

  methods: {
    ...mapActions('endpoints', ['getItens', 'buscarItem', 'deletarItem', 'getItem', 'clearItens'])
  }
}
</script>

<style lang="scss" src="./style.scss"></style>>
